(function () {
  let tempSearchWord = null;
  let tempFirstBrand = null;
  let tempPriceFrom = null;
  let tempPriceUpTo = null;
  const brandAndPriceEventAlias = "accela-brand-price";
  const openAccelaEventAlias = "accela-open-layer";
  const accelaSearchBarContainer = "accelasearch-bar-container-container";

  function sendConnectifEventsWhenReady(events) {
    if (
      window.connectif &&
      window.connectif.managed &&
      window.connectif.managed.isInitialized()
    ) {
      window.connectif.managed.sendEvents(events);
    } else {
      document.addEventListener(
        "connectif.managed.initialized",
        function onConnectifInitialized() {
          window.connectif.managed.sendEvents(events);
        },
        { once: true }
      );
    }
  }

  function debounce(func, timeout) {
    let timer;
    return function (data) {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(function () {
        func(data);
      }, timeout);
    };
  }

  function onAccelaLoaded() {
    const accelaSearchBarContainerElement = document.getElementById(
      accelaSearchBarContainer
    );
    if (!accelaSearchBarContainerElement) {
      console.warn(
        "Connectif - Accela Search - cannot find the Accela Search bar container with identifier " +
          accelaSearchBarContainer +
          ". Search events won't be tracked"
      );
      return;
    }

    accelaSearchBarContainerElement.addEventListener(
      "as-search-event",
      debounce(function onAccelaSearch(event) {
        const data = event.detail;
        // ignore page navigation
        if (data.page > 1) {
          return;
        }

        const searchWord = data.searchWord;
        const filters = data.filters || [];

        const firstBrandFilter = filters.find(function (f) {
          return f.key === "brand";
        });
        const firstBrand = firstBrandFilter ? firstBrandFilter.value : null;

        const sellingPriceFilter = filters.find(function (f) {
          return f.key === "sellingPrice";
        });

        const priceFrom =
          (sellingPriceFilter &&
            sellingPriceFilter.value.length === 2 &&
            sellingPriceFilter.value[0]) ||
          null;

        const priceUpTo =
          (sellingPriceFilter &&
            sellingPriceFilter.value.length === 2 &&
            sellingPriceFilter.value[1]) ||
          null;

        const searchWordHasChanged = searchWord !== tempSearchWord;
        const brandHasChanged = firstBrand !== tempFirstBrand;
        const priceRangeHasChanged =
          priceFrom !== tempPriceFrom || priceUpTo !== tempPriceUpTo;

        if (
          !searchWordHasChanged &&
          !brandHasChanged &&
          !priceRangeHasChanged
        ) {
          return;
        }

        tempSearchWord = searchWord;
        tempFirstBrand = firstBrand;
        tempPriceFrom = priceFrom;
        tempPriceUpTo = priceUpTo;

        function trackSearch() {
          const events = [];
          if (searchWordHasChanged) {
            events.push({
              type: "search",
              searchText: searchWord,
            });
          }
          if (brandHasChanged || priceRangeHasChanged) {
            events.push({
              type: "custom",
              eventAlias: brandAndPriceEventAlias,
              payload: {
                brand: firstBrand,
                priceFrom: priceFrom || 0,
                priceUpTo: priceUpTo || 0,
              },
            });
          }
          sendConnectifEventsWhenReady(events);
        }
        trackSearch();
      }, 1000)
    );

    function trackAccelaOpen() {
      sendConnectifEventsWhenReady([
        {
          type: "custom",
          eventAlias: openAccelaEventAlias,
          payload: {},
        },
      ]);
    }

    function onAccelaLayerOpen() {
      window.document.body.addEventListener(
        "as-close-layer",
        onAccelaLayerClose,
        {
          once: true,
        }
      );
      trackAccelaOpen();
    }

    function onAccelaLayerClose() {
      window.document.body.addEventListener(
        "as-open-layer",
        onAccelaLayerOpen,
        {
          once: true,
        }
      );
    }

    window.document.body.addEventListener("as-open-layer", onAccelaLayerOpen, {
      once: true,
    });
  }

  if (typeof window.ASOpenLayer === "function") {
    onAccelaLoaded();
  } else {
    window.document.body.addEventListener("as-loaded-layer", onAccelaLoaded, {
      once: true,
    });
  }
})();
